import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'AppHeader',
    uniqId: 'app_header',
})((theme, _) => {
    return {
        root: {
            backgroundColor: theme.palette.common.bgSection,
        },

        container: {
            paddingTop: 100,
            paddingBottom: 40,

            [theme.breakpoints.down('md')]: {
                paddingTop: 40,
                paddingBottom: 32,
            },

            [theme.breakpoints.down('sm')]: {
                padding: 24,
                paddingTop: 32,
            },
        },
    }
})

export default useStyles
