import AppSvgIcon from '@/components/AppSvgIcon'
import { Clear, Search } from '@mui/icons-material'
import { Autocomplete, TextField } from '@mui/material'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import useStyles from './SearchInput.styles'
import productsApi from '@/utils/api/products/products.api'
import { checkRequestSuccess } from '@/utils/helpers/axios'
import { AutoCompleteProductsResponse } from '@/utils/api/products'
import { onChangeQueryString } from '@/utils/helpers/common'

type optionsType = {
    label: AutoCompleteProductsResponse['name']
}

type SearchInputProps = {
    isSearch: boolean
}

const SearchInput = (props: SearchInputProps) => {
    const { isSearch } = props

    const { t } = useTranslation()
    const router = useRouter()
    const queryParms = useSearchParams()

    const inputRef = useRef<HTMLInputElement>(null)

    const [search, setSearch] = useState(queryParms?.get('search')?.toString() || '')
    const [loading, setLoading] = useState(false)
    const [autoCompleteProducts, setAutoCompleteProducts] = useState<optionsType[] | null>(null)

    const onSearch = (value: AutoCompleteProductsResponse['name']) => {
        try {
            setLoading(true)

            if (!value) return onChangeQueryString({ search: undefined })

            if (router.pathname === 'products') return onChangeQueryString({ search: value })

            return router.push(
                {
                    pathname: '/products',
                    query: {
                        ...router.query,
                        search: value,
                    },
                },
                undefined,
                { shallow: true }
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const getAutoCompleteProducts = async () => {
            try {
                setLoading(true)
                const { data: response } = await productsApi.getAutoCompleteProducts()
                if (checkRequestSuccess(response)) {
                    const result = response.params.map((item) => ({
                        label: item.name,
                    }))
                    setAutoCompleteProducts(result)
                }
            } finally {
                setLoading(false)
            }
        }

        getAutoCompleteProducts()
    }, [])

    useEffect(() => {
        if (inputRef.current && isSearch) inputRef.current.focus()
    }, [isSearch])

    useEffect(() => {
        if (!queryParms?.has('search')) setSearch('')
    }, [queryParms])

    const { classes } = useStyles()

    return (
        <Autocomplete
            freeSolo
            disablePortal
            value={search}
            onChange={(e: any, value: any, reason) => {
                const searchValue = reason === 'selectOption' ? value.label : value

                setSearch(searchValue)
                onSearch(searchValue)
            }}
            onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                    event.preventDefault()
                    const value = event.target.value
                    if (value) return

                    setSearch('')
                    onSearch('')
                    return
                }
            }}
            loading={loading}
            disableClearable
            options={autoCompleteProducts || []}
            size="small"
            fullWidth
            classes={{
                root: classes.autocompleteRoot,
                paper: classes.paperRoot,
                option: classes.option,
            }}
            clearIcon={<AppSvgIcon component={Clear} />}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t('placeholder.searchKeyword', { ns: 'form' })}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Search fontSize="small" />,
                        classes: {
                            root: classes.inputRoot,
                        },
                    }}
                    inputRef={inputRef}
                />
            )}
        />
    )
}

export default SearchInput
