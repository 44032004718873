import { Stack, Typography } from '@mui/material'
import AppLink from '@/components/AppLink'
import { useTranslation } from 'next-i18next'
import { useAppSelector } from '@/hooks'
import { selectFetchProductSortResourceStatus, selectSeriesList } from '@/store/productSortResource/reducer'
import WrapperStates from '@/components/WrapperStates'
import AppSpinner from '@/components/AppSpinner'
import { useRouter } from 'next/router'
import { SeriesResponseType } from '@/utils/api/productSortResource'

const ProductSerieList = () => {
    const { t } = useTranslation()
    const router = useRouter()

    const $s_serieList = useAppSelector(selectSeriesList)
    const $s_fetchingSeriList = useAppSelector(selectFetchProductSortResourceStatus)

    const onQueryProductSerie = (serieId: SeriesResponseType['id']) => {
        if (router.pathname !== '/products')
            return router.push({
                pathname: '/products',
                query: { seri_id: serieId },
            })

        return router.push({
            query: { ...router.query, seri_id: serieId },
        })
    }

    return (
        <Stack spacing={2}>
            <Typography variant="bodySemi16" color="white">
                {t('products', { ns: 'navigation' })}
            </Typography>

            {$s_fetchingSeriList ? (
                <WrapperStates minHeight="200"><AppSpinner size="small" color="secondary" /></WrapperStates>
            ) : (
                $s_serieList?.map((serie) => (
                    <div className="cursor-pointer" onClick={() => onQueryProductSerie(serie.id)} key={serie.id}>
                        <Typography color="white">{serie.name}</Typography>
                    </div>
                ))
            )}
        </Stack>
    )
}

export default ProductSerieList
