import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'MobileSidebar',
})(() => {
    return {
        papperRoot: {
            width: 250,
        },
    }
})
export default useStyles
