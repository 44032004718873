import AppHeader from '@/components/AppHeader'
import React from 'react'
import useStyles from './Main.styles'
import AppFooter from '@/components/AppFooter'
import { useRouter } from 'next/router'
import HeaderCheckoutPage from '@/containers/HeaderCheckoutPage'
import { Box } from '@mui/material'

type MainProps = {
    children: React.ReactNode
}

const Main = (props: MainProps) => {
    const { children } = props

    const router = useRouter()

    const isCheckoutPage = /^\/checkout/g.test(router.asPath)

    const { classes } = useStyles()

    return (
        <div className={classes.root}>
            {!isCheckoutPage ? <AppHeader /> : <HeaderCheckoutPage />}

            <Box bgcolor={!isCheckoutPage ? 'common.white' : '#FAFDFF'}>{children}</Box>

            <AppFooter />
        </div>
    )
}

export default Main
