import { useMediaQuery, Theme, Box, NoSsr } from '@mui/material'
import useStyles from './AppHeader.styles'
import { MENU } from '@/utils/constants/menu.constant'
import { useTranslation } from 'next-i18next'
import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/Menu'
import AppLink from '../AppLink'
import AppImage from '../AppImage'
import AppIconButton from '../AppIconButton'
import UserAuthButton from './components/UserAuthButton'
import UserCartButton from './components/UserCartButton'
import { usePathname } from 'next/navigation'
import ChangeLanguageButton from './components/ChangeLanguageButton'
import { useEffect, useState } from 'react'
import SearchInput from './components/SearchInput'
import { useRouter } from 'next/router'
import MobileSidebar from './components/MobileSidebar'

const AppHeader = () => {
    const { t } = useTranslation()
    const pathname = usePathname()
    const router = useRouter()

    const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const [isSearch, setIsSearch] = useState(false)
    const [showSidebar, setShowSidebar] = useState(false)

    const handleShowSidebar = () => setShowSidebar(true)
    const handleCloseSidebar = () => setShowSidebar(false)

    const { classes } = useStyles({ isSearchOpen: isSearch })

    useEffect(() => {
        if (isSearch) setIsSearch(false)
    }, [router.query.search])

    return (
        <div className={classes.root}>
            <NoSsr>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                    }}
                    className="dfire-container"
                >
                    {isDownMd && (
                        <>
                            <AppIconButton
                                icon={MenuIcon}
                                hoverColor="primary"
                                variant="filled"
                                onClick={handleShowSidebar}
                            />
                            <MobileSidebar show={showSidebar} onOpen={handleShowSidebar} onClose={handleCloseSidebar} />
                        </>
                    )}

                    <AppLink href="/" minWidth="18%" maxWidth={160}>
                        <AppImage maxWidth="100%" priority fill sizes="160px" src="/img/logo-full.png" />
                    </AppLink>

                    {!isDownMd && (
                        <div>
                            {MENU.map((item) => (
                                <AppLink
                                    key={item.localeCode}
                                    href={item.path}
                                    variant="bodySemi16"
                                    hoverColor="primary"
                                    className={`no-underline ms-1 p-4 ${pathname?.includes(item.activeAt) && 'active'}`}
                                >
                                    {t(item.localeCode, { ns: 'navigation' })}
                                </AppLink>
                            ))}
                        </div>
                    )}

                    <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                        {!isDownMd && <ChangeLanguageButton />}

                        <AppIconButton
                            icon={SearchIcon}
                            hoverColor="primary"
                            variant="filled"
                            onClick={() => setIsSearch(!isSearch)}
                        />

                        <UserAuthButton />

                        <UserCartButton />
                    </Box>
                </Box>

                <Box className={`search-input ${isSearch && 'active'}`}>
                    <SearchInput isSearch={isSearch} />
                </Box>
            </NoSsr>
        </div>
    )
}

export default AppHeader
