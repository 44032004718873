import useStyles from './AppFooter.styles'
import { useTranslation } from 'next-i18next'
import { Stack, Typography } from '@mui/material'
import AppImage from '../AppImage'
import AppLink from '../AppLink'
import { CONTACT_INFO, SECTION_CONTACT_HTML_ID } from '@/utils/constants/common.constant'
import ProductSerieList from './components/ProductSerieList'
import SupportList from './components/SupportList'
import { useAppSelector } from '@/hooks'
import { selectContacts } from '@/store/common/reducer'

const AppFooter = () => {
    const { t, i18n } = useTranslation()
    const isEnglish = i18n.language.includes('en')

    const $s_contacts = useAppSelector(selectContacts)

    const { classes } = useStyles()

    return (
        <div className={classes.root}>
            <div className={`dfire-container ${classes.container}`}>
                <Stack flexDirection="row" justifyContent="space-between" flexWrap="wrap" gap="17.5px">
                    <AppLink href="/" flexBasis="160px">
                        <AppImage
                            fill
                            maxWidth="100%"
                            priority
                            src="/img/logo-full.png"
                            defaultPlaceholderVariant="default"
                        />
                    </AppLink>

                    <ProductSerieList />

                    <Stack spacing={2}>
                        <Typography variant="bodySemi16" color="white">
                            {t('aboutUs', { ns: 'navigation' })}
                        </Typography>
                        <AppLink href="/blogs">
                            <Typography color="white">{t('blogs', { ns: 'navigation' })}</Typography>
                        </AppLink>
                        <AppLink href={`/about-us#${SECTION_CONTACT_HTML_ID}`} scroll={false} replace={true}>
                            <Typography color="white">{t('contact', { ns: 'navigation' })}</Typography>
                        </AppLink>
                    </Stack>

                    <SupportList />

                    <Stack alignItems="start" spacing={2}>
                        <Typography variant="bodySemi16" color="white">
                            {isEnglish ? 'Follow us' : 'Theo dõi chúng tôi'}
                        </Typography>
                        <div className="flex gap-2 justify-center items-center">
                            <AppLink href={$s_contacts?.facebook_url || CONTACT_INFO.facbook_url}>
                                <AppImage
                                    fill
                                    maxWidth={undefined}
                                    className="cursor-pointer"
                                    src="/icon/ic-fb-white.svg"
                                />
                            </AppLink>
                            <AppLink href={$s_contacts?.instagram_url || CONTACT_INFO.ig_url}>
                                <AppImage
                                    fill
                                    maxWidth={undefined}
                                    className="cursor-pointer"
                                    src="/icon/ic-instagram-white.svg"
                                />
                            </AppLink>
                        </div>
                    </Stack>
                </Stack>

                <Typography variant="captionReg12" textAlign="center" mt={10} color="white">
                    Copyright © {new Date().getFullYear()} DragonFire - All right reserve
                </Typography>
            </div>
        </div>
    )
}

export default AppFooter
