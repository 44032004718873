import { RawAxiosRequestHeaders } from 'axios'
import React from 'react'

export enum ProductsApiRoute {
    PRODUCTS_LIST_ROUTE = '/products/getListProductByFilter.json',
    PRODUCT_DETAIL_ROUTE = '/products/getDetail.json',

    PRODUCT_BANNER_ROUTE = '/productBanners/getList.json',

    PRODUCTS_BEST_SELLER_ROUTE = '/bestSellers/getList.json',

    PRODUCT_BY_SERIE_ROUTE = '/series/getListByFilter.json',

    AUTO_COMPLETE_PRODUCTS_ROUTE = '/products/getListNameByFilter.json',
}

type PayloadType = {
    headers?: RawAxiosRequestHeaders
}

export type ProductsPayload = {
    params: {
        page: SafeNumber
        limit: SafeNumber
        search?: string
        seri_id?: string[]
        switch_id?: string[]
        price_from?: EmptySafeNumber
        price_to?: EmptySafeNumber
    }
} & PayloadType

export type ProductDetailPayloadType = {
    params: {
        product_id: number | string
    }
} & PayloadType

export type ProductDetailType = {
    id: SafeNumber
    color_id: SafeNumber
    is_main_switch: boolean
    inventory: number
    price: string
    color: React.CSSProperties['color']
    name: string
    switch: ProductSwitchType
    product_images: ProductImage[]
}

/**
 * Custom response product detail
 */
export type CustomProductSetType = {
    id: ProductDetailType['id']
    name: ProductDetailType['name']
    price: ProductDetailType['price']
    inventory: ProductDetailType['inventory']
    switch_id: ProductSwitchType['id']
    switch_name: ProductSwitchType['name']
    switch_path: ProductSwitchType['path']
    is_main_switch: ProductDetailType['is_main_switch']
}

export type TransformProductDetailType = {
    color: ProductDetailType['color']
    color_id: ProductDetailType['color_id']
    color_name?: ProductDetailType['name']
    product_set: CustomProductSetType[]
    product_images: ProductDetailType['product_images']
}

export type ProductDescriptionType = {
    descriptions: ProductDescription[]
    files: ProductFile[]
}

export type ProductType = {
    id: SafeNumber
    name: string
    product_details: ProductBundle[]

    product_type_name?: string
    seri_name?: string
}

export type ProductBundle = {
    color_id: SafeNumber
    color: React.CSSProperties['color']
    color_name: string
    color_switches: ProductSingleSet[]
}

export type ProductSingleSet = {
    product_detail_id: number
    is_main_switch: boolean
    price: SafeNumber
    inventory: SafeNumber
    switch: ProductSwitchType
    product_images: ProductImage[]
}

export type ProductSwitchType = {
    id: SafeNumber
    name: string
    path: string | null
}

export type ProductKeycapType = {
    id: SafeNumber
    sort: number
    path: string
    content: string
}

export type ProductImage = {
    id: SafeNumber
    sort: SafeNumber
    path: string
}

export type ProductDescription = {
    id: SafeNumber
    type: 'GENERAL_INTRODUCTION' | 'DETAIL_SPECIFICATIONS' | 'COMPATIBLE' | 'PACKAGE' | 'SUPPORT'
    content: string
}

export type ProductFile = {
    id: SafeNumber
    path: string
}

/**
 * Response types
 */
export type ProductsResponseType = {
    total: number
    items: ProductType[]
}

export type ProductDetailResponseType = {
    id: SafeNumber
    name: string
    seri: {
        id: number
        code: string
        background: React.CSSProperties['backgroundColor']
        name: string
    }
    layout: {
        id: number
        name: string
    }
    description: string
    product_details: ProductDetailType[]
    product_descriptions: ProductDescriptionType
    product_keycaps: ProductKeycapType[]
}

export type BestSellerDetails = {
    color_id: SafeNumber
    is_main_switch: boolean
    price: SafeNumber
    color: React.CSSProperties['color']
    name: string
    product_images: ProductImage[]
}

export type BestSellerResponse = {
    id: SafeNumber
    name: string
    product_type_name: string
    product_details: BestSellerDetails[]
}

export type ProductBySerieResponse = {
    id: SafeNumber
    code: string
    background: React.CSSProperties['backgroundColor']
    name: string
    layout_name: SafeNumber
    price_description: string
    description: string
    product_images: ProductImage[]
}

export type ProductBanner = {
    id: SafeNumber
    path: string
    description: string
}

export type AutoCompleteProductsResponse = {
    name: string
}
