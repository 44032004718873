import _get from 'lodash/get'
import { makeStyles } from 'tss-react/mui'

import type { AppLinkProps } from '@/components/AppLink/AppLink'

type StylesParams = {
    hoverColor?: AppLinkProps['hoverColor']
}

const useStyles = makeStyles<StylesParams>({
    name: 'AppLink',
    uniqId: 'i5uc6D',
})((theme, params) => {
    let hoverColor: React.CSSProperties['color'] = ''
    let hoverBackground: React.CSSProperties['backgroundColor'] = ''

    switch (params.hoverColor) {
        case 'primary': {
            hoverColor = theme.palette.common.textPrimary
            hoverBackground = theme.palette.common.primary
            break
        }

        case 'secondary': {
            hoverColor = theme.palette.secondary.main
            break
        }

        case 'error': {
            hoverColor = theme.palette.error.main
            break
        }

        default: {
            hoverColor = _get(theme.palette, params.hoverColor as string)! || params.hoverColor!
            break
        }
    }

    return {
        root: {
            transition: '0.3s',

            '&:hover, &.active': {
                color: hoverColor,
                backgroundColor: hoverBackground,
            },
        },
    }
})

export default useStyles
