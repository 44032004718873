import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import Popper, { PopperPlacementType, PopperProps } from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Button, { ButtonProps } from '@mui/material/Button'
import { Grow } from '@mui/material'
import useStyles from './AppPopper.styles'

type CustomPopperProps = {
    trigger: React.ReactElement<ButtonProps, typeof Button>
    content: React.ReactNode | (({ handleClose }: { handleClose: () => void }) => React.ReactNode)
    open?: boolean
    onClose?: () => void
    placement?: PopperPlacementType
}

type AppPopperProps = CustomPopperProps & Omit<PopperProps, keyof CustomPopperProps>

const AppPopper = forwardRef((props: AppPopperProps, ref: React.ForwardedRef<any>) => {
    const { sx, trigger, content, open: propOpen, onClose, placement = 'bottom-start', ...rest } = props

    const { theme, css } = useStyles()

    const [open, setOpen] = useState(propOpen || false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => setOpen(!open)

    const handleClose = (event: any) => {
        if (!anchorRef.current || !anchorRef.current.contains(event.target as Node)) {
            setOpen(false)
            onClose && onClose()
        }
    }

    useImperativeHandle(ref, () => ({
        open,
    }))

    return (
        <>
            {React.cloneElement(trigger, {
                ref: anchorRef,
                onClick: handleToggle,
            })}
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement={placement}
                {...rest}
                sx={{ ...sx }}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement.includes('bottom') ? 'left top' : 'bottom left',
                        }}
                    >
                        <div>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    {typeof content === 'function' ? (content as Function)({ handleClose }) : content}
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </>
    )
})

export default AppPopper
