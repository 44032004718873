import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'SearchInput',
})((theme) => {
    return {
        autocompleteRoot: {
            maxWidth: '30%',

            [theme.breakpoints.down('md')]: {
                maxWidth: '70%',
            },
        },

        paperRoot: {
            borderRadius: 0,
        },

        option: {
            ...theme.typography.bodyReg16,
            padding: '10px 16px !important',
        },

        inputRoot: {
            borderRadius: 0,
            padding: '10px 16px !important',

            '.MuiSvgIcon-root': {
                color: theme.palette.secondary.main,
            },

            '&:hover .MuiSvgIcon-root, &:focus .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
        },
    }
})

export default useStyles
