import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'MainLayout',
    uniqId: 'main_layout',
})(() => {
    return {
        root: {
            maxWidth: '1728px',
            overflowX: 'clip',
            marginLeft: 'auto',
            marginRight: 'auto',
            boxSizing: 'border-box',
            position: 'relative',
            height: '100vh',
        },
        childrenContainer: {
            backgroundColor: '#fff',
        },
    }
})

export default useStyles
