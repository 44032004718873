import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ isSearchOpen: boolean }>({
    name: 'AppHeader',
    uniqId: 'AdQWPC',
})((theme, params) => ({
    root: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        height: 164,
        boxShadow: !params.isSearchOpen ? '0px 4px 10px 0px rgba(0, 0, 0, 0.05)' : 'unset',
        transition: '.2s',

        [theme.breakpoints.down('md')]: {
            height: 116,
        },

        '.search-input': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            height: 0,

            width: '100%',
            position: 'absolute',

            left: '50%',
            bottom: '50%',
            opacity: 0,
            zIndex: -1,
            transform: 'translateX(-50%)',
            transition: '.2s',

            '&.active': {
                height: 86,
                bottom: '-30%',
                zIndex: 1,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
            },

            [theme.breakpoints.down('md')]: {
                '&.active': {
                    width: '100%',
                    bottom: '-65%',
                    alignItems: 'center',
                },
            },
        },
    },
}))

export default useStyles
