import { Box, BoxProps } from '@mui/material'
import React, { PropsWithChildren, forwardRef } from 'react'

export type WrapperStates = {
    backgroundColor?: React.CSSProperties['backgroundColor']
} & PropsWithChildren &
    BoxProps

const WrapperStates = forwardRef((props: WrapperStates, ref: React.ForwardedRef<any>) => {
    const { minHeight = 300, minWidth = '100%', backgroundColor = 'inherit', children } = props

    return (
        <Box
            ref={ref}
            minHeight={minHeight}
            width="100%"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor,
                minWidth,
            }}
        >
            {children}
        </Box>
    )
})
export default WrapperStates
