import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'UserAuthButton',
})((theme) => {
    return {
        accountMenu: {
            backgroundColor: theme.palette.common.white,
            width: 168,
        },

        accountMenuItem: {
            padding: '1.2rem 1rem',

            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
        },
    }
})

export default useStyles
