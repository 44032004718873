import AppLink from '@/components/AppLink'
import { SUPPORT_MENU } from '@/utils/constants/support.constant'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'

const SupportList = () => {
    const { t } = useTranslation()

    return (
        <Stack spacing={2}>
            <Typography variant="bodySemi16" color="white">
                {t('support', { ns: 'navigation' })}
            </Typography>

            {SUPPORT_MENU.map((item) => (
                <AppLink key={item.type} href={item.slug} shallow>
                    <Typography color="white">{t(item.translateKey, { ns: 'support' })}</Typography>
                </AppLink>
            ))}
        </Stack>
    )
}

export default SupportList
