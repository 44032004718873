import { Typography } from '@mui/material'
import { AccountCircleOutlined, Person2Outlined } from '@mui/icons-material'
import AppLink from '@/components/AppLink'

import useStyles from './UserAuthButton.styles'
import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { selectAuthUser, selectFetchUserStatus, signOut } from '@/store/auth/reducer'
import { removeCart } from '@/store/cart/reducer'

import AppButton from '@/components/AppButton'
import AppPopper from '@/components/AppPopper'
import AppIconButton from '@/components/AppIconButton'
import AppSpinner from '@/components/AppSpinner'
import { useRouter } from 'next/router'
import { ACCOUNT_MENU } from '@/utils/constants/account.constanst'
import { userNameBuilder } from '@/utils/helpers/user'

type MenuPropsType = {
    onClick: (e: any) => void
}

const MenuUnauth = (props: MenuPropsType) => {
    const { onClick: onClickProp } = props

    const router = useRouter()

    const redirectUrl = encodeURIComponent(router.asPath)

    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <AppLink href={`/sign-in?redirectUrl=${redirectUrl}`} sx={{ textDecoration: 'none' }}>
                <Typography
                    className={classes.accountMenuItem}
                    variant="bodySemi14"
                    textAlign="start"
                    onClick={(e: any) => onClickProp(e)}
                >
                    {t('login', { ns: 'user' })}
                </Typography>
            </AppLink>
            <AppLink
                href={`/register?redirectUrl=${redirectUrl}`}
                sx={{ textDecoration: 'none' }}
                onClick={(e: any) => onClickProp(e)}
            >
                <Typography className={classes.accountMenuItem} variant="bodySemi14" textAlign="start">
                    {t('register', { ns: 'user' })}
                </Typography>
            </AppLink>
        </>
    )
}

const MenuAuth = (props: MenuPropsType) => {
    const { onClick: onClickProp } = props
    const { t } = useTranslation()
    const { classes } = useStyles()

    const dispatch = useAppDispatch()
    const handleSignOut = (e: any) => {
        dispatch(signOut(undefined))
        dispatch(removeCart(undefined))
        return onClickProp(e)
    }

    return (
        <>
            <AppLink
                href={`${ACCOUNT_MENU[0].slug}`}
                sx={{ textDecoration: 'none' }}
                onClick={(e: any) => onClickProp(e)}
            >
                <Typography className={classes.accountMenuItem} variant="bodySemi14" textAlign="start">
                    {t('account', { ns: 'user' })}
                </Typography>
            </AppLink>

            <AppButton
                fullWidth
                className={classes.accountMenuItem}
                sx={{ justifyContent: 'start' }}
                onClick={handleSignOut}
            >
                {t('logout', { ns: 'user' })}
            </AppButton>
        </>
    )
}

const UserAuthButton = () => {
    const { classes } = useStyles()

    const $s_authUser = useAppSelector(selectAuthUser)
    const $s_fetchingAuthUserStatus = useAppSelector(selectFetchUserStatus)
    const hasAuthUser = !!$s_authUser

    const renderedTrigger = () => {
        if (hasAuthUser)
            return (
                <AppButton
                    variant="contained"
                    color="secondary"
                    startIcon={<AccountCircleOutlined />}
                    sx={{ py: '12px' }}
                >
                    <Typography variant="bodySemi14" color="common.white">
                        {userNameBuilder($s_authUser.full_name)}
                    </Typography>
                </AppButton>
            )
        return $s_fetchingAuthUserStatus ? (
            <AppIconButton size="small" variant="filled">
                <AppSpinner sx={{ width: 24, height: 24 }} />
            </AppIconButton>
        ) : (
            <AppIconButton
                icon={Person2Outlined}
                hoverColor="primary"
                variant="filled"
                disabled={$s_fetchingAuthUserStatus}
            />
        )
    }

    return (
        <AppPopper
            placement="bottom-end"
            sx={{ top: '8px !important', zIndex: 10 }}
            trigger={renderedTrigger()}
            content={({ handleClose }) => (
                <div className={`${classes.accountMenu} shadow-md`}>
                    {hasAuthUser ? <MenuAuth onClick={handleClose} /> : <MenuUnauth onClick={handleClose} />}
                </div>
            )}
        />
    )
}

export default UserAuthButton
