import AppSvgIcon from '@/components/AppSvgIcon'
import { KeyboardArrowDown } from '@mui/icons-material'
import useStyles from './ChangeLanguageButton.styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import AppPopper from '@/components/AppPopper'
import AppButton from '@/components/AppButton'
import AppLink from '@/components/AppLink'
import { LANGUAGES_MENU } from '@/utils/constants/menu.constant'
import { Theme, useMediaQuery } from '@mui/material'

const ChangeLanguageButton = () => {
    const router = useRouter()
    const { i18n } = useTranslation()

    const { classes } = useStyles()

    const handleChangeLanguage = (localeToChange: string) => {
        if (localeToChange !== i18n.language) return router.events.on('routeChangeComplete', () => router.reload())
    }
    const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const getCurrentLanguage = () => LANGUAGES_MENU.find((item) => item.value === i18n.language)?.label

    return (
        <AppPopper
            sx={{ width: { xs: '100%', md: 123 }, zIndex: 10 }}
            placement={isDownMd ? 'bottom-start' : 'bottom-end'}
            trigger={
                <AppButton sx={{ mt: { xs: 32 / 8, md: 0 } }} endIcon={<AppSvgIcon component={KeyboardArrowDown} />}>
                    {getCurrentLanguage()}
                </AppButton>
            }
            content={
                <div className={classes.menuWrap}>
                    {LANGUAGES_MENU.map((item) => (
                        <AppButton
                            key={item.value}
                            onClick={() => {
                                handleChangeLanguage(item.value)
                            }}
                            component={AppLink}
                            href={router.asPath || '/'}
                            //@ts-ignore
                            locale={item.value}
                            fullWidth
                            className={classes.contentButton}
                            size="small"
                        >
                            {item.label}
                        </AppButton>
                    ))}
                </div>
            }
        />
    )
}

export default ChangeLanguageButton
