import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'AppPopper',
})((_) => {
    return {
        root: {},
    }
})

export default useStyles
