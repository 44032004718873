import AppImage from '@/components/AppImage'
import { Box } from '@mui/material'

const HeaderCheckoutPage = () => {
    return (
        <Box component="header" bgcolor="#FAFDFF">
            <Box className="dfire-container" py={5}>
                <AppImage
                    src="/img/logo-checkout-page.png"
                    defaultPlaceholderVariant="default"
                    width={160}
                    height={66}
                />
            </Box>
        </Box>
    )
}

export default HeaderCheckoutPage
