import AppIconButton from '@/components/AppIconButton'
import AppLink from '@/components/AppLink'
import { Badge } from '@mui/material'
import { ShoppingCartOutlined } from '@mui/icons-material'
import { useAppSelector } from '@/hooks'
import { selectFetchCartStatus, selectTotalItemsCart } from '@/store/cart/reducer'
import AppSpinner from '@/components/AppSpinner'
import { useRouter } from 'next/router'
import { selectAuthUser } from '@/store/auth/reducer'
import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import AppButton from '@/components/AppButton'

const UserCartButton = () => {
    const $s_authUser = useAppSelector(selectAuthUser)
    const $s_cartFetchingStatus = useAppSelector(selectFetchCartStatus)
    const $s_cartTotalItems = useAppSelector(selectTotalItemsCart)

    const { t } = useTranslation()
    const router = useRouter()

    const onClickUserCartButton = () => {
        if (!$s_authUser) return toast.warn(t('loginFirst', { ns: 'common' }))
    }

    const render = () => {
        if ($s_cartFetchingStatus)
            return (
                <AppIconButton size="small" variant="filled">
                    <AppSpinner sx={{ width: 24, height: 24 }} />
                </AppIconButton>
            )

        // if (!$s_cartTotalItems || $s_cartTotalItems === 0) return <AppIconButton icon={ShoppingCartOutlined} hoverColor="primary" variant="filled" />

        return (
            <Badge max={9} badgeContent={$s_cartTotalItems} color="warning" sx={{ fontSize: 14 }}>
                <AppIconButton icon={ShoppingCartOutlined} hoverColor="primary" variant={router.route === '/cart' ? 'contained' : 'filled'} />
            </Badge>
        )
    }

    return (
        <AppLink href={$s_authUser ? '/cart' : 'sign-in'} onClick={onClickUserCartButton}>
            {render()}
        </AppLink>
    )
}

export default UserCartButton
