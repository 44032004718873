import { commonAxios } from '@/libs'
import {
    AutoCompleteProductsResponse,
    BestSellerResponse,
    ProductBanner,
    ProductBySerieResponse,
    ProductDetailPayloadType,
    ProductDetailResponseType,
    ProductsApiRoute,
    ProductsPayload,
    ProductsResponseType,
} from './products.types.api'
import { AxiosResponseData } from '@/libs/axios'

const productsApi = {
    getProductsList: (payload: ProductsPayload) => {
        return commonAxios.get<AxiosResponseData<ProductsResponseType>>(ProductsApiRoute.PRODUCTS_LIST_ROUTE, {
            params: {
                ...payload.params,
                seri_id: payload.params?.seri_id ? payload.params.seri_id.join(',') : undefined,
                switch_id: payload.params?.switch_id ? payload.params.switch_id.join(',') : undefined,
            },
        })
    },
    getProductDetail: (payload: ProductDetailPayloadType) => {
        return commonAxios.get<AxiosResponseData<ProductDetailResponseType>>(ProductsApiRoute.PRODUCT_DETAIL_ROUTE, {
            params: { ...payload.params },
        })
    },
    getProductBanner: () => {
        return commonAxios.get<AxiosResponseData<ProductBanner[]>>(ProductsApiRoute.PRODUCT_BANNER_ROUTE)
    },

    // Home pages API
    getBestSeller: () => {
        return commonAxios.get<AxiosResponseData<BestSellerResponse[]>>(ProductsApiRoute.PRODUCTS_BEST_SELLER_ROUTE)
    },

    getProductBySerie: () => {
        return commonAxios.get<AxiosResponseData<ProductBySerieResponse[]>>(ProductsApiRoute.PRODUCT_BY_SERIE_ROUTE)
    },

    getAutoCompleteProducts: () => {
        return commonAxios.get<AxiosResponseData<AutoCompleteProductsResponse[]>>(
            ProductsApiRoute.AUTO_COMPLETE_PRODUCTS_ROUTE
        )
    },
}

export default productsApi
