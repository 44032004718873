import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'ChangeLanguageButton',
})((theme) => {
    return {
        menuWrap: {
            boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
            backgroundColor: theme.palette.common.white,
            width: '100%',

            [theme.breakpoints.down('md')]: {
                boxShadow: 'unset',
            },
        },
        contentButton: {
            justifyContent: 'start',
            padding: theme.spacing(19 / 8, 16 / 8),

            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },

            [theme.breakpoints.down('md')]: {
                justifyContent: 'center',
            },
        },
    }
})

export default useStyles
