export const ACCOUNT_MENU = [
    {
        type: 'account',
        slug: '/user/account/personal',
        activeAtRegex: new RegExp('^/user/account/personal'),
        translateKey: 'personal',
    },
    // {
    //     type: 'account',
    //     slug: '/user/account/payment',
    //     activeAtRegex: new RegExp('^/user/account/payment'),
    //     translateKey: 'payment',
    // },
    {
        type: 'account',
        slug: '/user/account/address',
        activeAtRegex: new RegExp('^/user/account/address'),
        translateKey: 'address',
    },
    {
        type: 'account',
        slug: '/user/account/password',
        activeAtRegex: new RegExp('^/user/account/password'),
        translateKey: 'password',
    },
    {
        type: 'order',
        slug: '/user/purchase',
        activeAtRegex: new RegExp('^/user/purchase'),
        translateKey: 'order',
    },
    // {
    //     type: 'coupons',
    //     slug: 'coupons',
    //     activeAtRegex: new RegExp('^coupons'),
    //     translateKey: 'coupons',
    // },
] as const

export const PURCHASED_STATUS_MENU = [
    {
        type: 'all',
        activeAtRegex: null,
        translateKey: 'allPurchased',
    },
    {
        type: 0,
        activeAtRegex: null,
        translateKey: 'ordered',
    },
    {
        type: 1,
        activeAtRegex: null,
        translateKey: 'ordered',
    },
    {
        type: 2,
        activeAtRegex: null,
        translateKey: 'preparing',
    },
    {
        type: 3,
        activeAtRegex: null,
        translateKey: 'delivering',
    },
    {
        type: 4,
        activeAtRegex: null,
        translateKey: 'delivered',
    },
    {
        type: 5,
        activeAtRegex: null,
        translateKey: 'completed',
    },
    {
        type: 6,
        activeAtRegex: null,
        translateKey: 'cancel',
    },
] as const

export const PURCHASED_STATUSES = {
    0: { color: '#525252', text: 'ordered' },
    1: { color: '#525252', text: 'ordered' },
    2: { color: '#6929C4', text: 'preparing' },
    3: { color: '#0043CE', text: 'delivering' },
    4: { color: '#005D5D', text: 'delivered' },
    5: { color: '#0E6027', text: 'completed' },
    6: { color: '#A2191F', text: 'cancel' },
} as const
