import { SwipeableDrawer } from '@mui/material'
import { MENU } from '@/utils/constants/menu.constant'
import AppLink from '@/components/AppLink'
import { usePathname } from 'next/navigation'
import { useTranslation } from 'next-i18next'
import useStyles from './MobileSidebar.styles'
import AppImage from '@/components/AppImage'
import ChangeLanguageButton from '../ChangeLanguageButton'

type MobileSidebarProps = {
    show: boolean
    onOpen: () => void
    onClose: () => void
}

const MobileSidebar = (props: MobileSidebarProps) => {
    const { show, onOpen, onClose } = props

    const { t } = useTranslation()
    const pathname = usePathname()

    const { classes } = useStyles()

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    return (
        <SwipeableDrawer
            open={!!show}
            onOpen={onOpen}
            onClose={onClose}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            PaperProps={{ classes: { root: classes.papperRoot } }}
        >
            <AppLink href="/" minWidth="18%" maxWidth={160} mx={2} my={4} onClick={onClose}>
                <AppImage maxWidth="100%" fill sizes="160px" priority src="/img/logo-full.png" />
            </AppLink>

            {MENU.map((item) => (
                <AppLink
                    key={item.localeCode}
                    href={item.path}
                    variant="bodySemi16"
                    hoverColor="primary"
                    className={`no-underline ms-1 p-4 ${pathname?.includes(item.activeAt) && 'active'}`}
                    maxWidth="70%"
                    onClick={onClose}
                >
                    {t(item.localeCode, { ns: 'navigation' })}
                </AppLink>
            ))}

            <ChangeLanguageButton />
        </SwipeableDrawer>
    )
}

export default MobileSidebar
